import revive_payload_client_PjPaE00XAJ from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_wkddva3ag22vsn7omrmthtsgya/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_WqRfgteJKY from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_wkddva3ag22vsn7omrmthtsgya/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pog3Wld6Rk from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_wkddva3ag22vsn7omrmthtsgya/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_4uazXPpuD6 from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_wkddva3ag22vsn7omrmthtsgya/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_OANYWe4K3k from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_wkddva3ag22vsn7omrmthtsgya/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/funrize/.nuxt/components.plugin.mjs";
import prefetch_client_f981fnlETQ from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_wkddva3ag22vsn7omrmthtsgya/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import firebase_client_xiI7lcwQ7N from "/app/node_modules/.pnpm/@netgame+push-notifications@1.9.13_magicast@0.3.4_rollup@4.20.0/node_modules/@netgame/push-notifications/dist/runtime/plugins/firebase.client.mjs";
import plugin_fsj5dyf8pA from "/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.3.2_magicast@0.3.4_rollup@4.20.0_webpack@5.93.0/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import i18n_LiHNtwnMXw from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_magicast@0.3.4_rollup@4.20.0_vue@3.4.38_typescript@5.4.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_BDiaZcPVYD from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_M60Gzlqt60 from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.3.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_wkddva3ag22vsn7omrmthtsgya/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_raygun_client_6WZuYPwvB9 from "/app/node_modules/.pnpm/@netgame+layer-raygun@1.7.9_magicast@0.3.4_rollup@4.20.0/node_modules/@netgame/layer-raygun/plugins/01.raygun.client.ts";
import mask_Tk3SUcMqzt from "/app/apps/funrize/plugins/mask.ts";
import vue3_toastify_OGYNDsiW9E from "/app/apps/funrize/plugins/vue3-toastify.ts";
export default [
  revive_payload_client_PjPaE00XAJ,
  unhead_WqRfgteJKY,
  router_pog3Wld6Rk,
  payload_client_4uazXPpuD6,
  check_outdated_build_client_OANYWe4K3k,
  components_plugin_KR1HBZs4kY,
  prefetch_client_f981fnlETQ,
  firebase_client_xiI7lcwQ7N,
  plugin_fsj5dyf8pA,
  i18n_LiHNtwnMXw,
  plugin_BDiaZcPVYD,
  chunk_reload_client_M60Gzlqt60,
  _01_raygun_client_6WZuYPwvB9,
  mask_Tk3SUcMqzt,
  vue3_toastify_OGYNDsiW9E
]